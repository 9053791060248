import {RouteRecordRaw} from "vue-router";
import layout from "@/layout/layout.vue";

const menuList: Array<RouteRecordRaw> = [
    {
        path: '/dashboard',
        name: 'dashboard',
        redirect: '/dashboard/loan',
        meta: {
            label: '仪表盘',
            icon: 'PieChartOutlined'
        },
        children: [
            {
                path: '/dashboard/loan',
                name: 'loanDash',
                component: () => import('@/views/dashboard/loanDash.vue'),
                meta: {
                    label: '贷款数据概览'
                }
            },
            {
                path: '/dashboard/dataTable',
                name: 'dataTable',
                component: () => import('@/views/dashboard/dataTable.vue'),
                meta: {
                    label: '数据报表'
                }
            },
            {
                path: '/dashboard/risk',
                name: 'riskDash',
                component: () => import('@/views/dashboard/riskDash.vue'),
                meta: {
                    label: '风险概览'
                }
            }
        ]
    },
    {
        path: '/loan',
        name: 'loan',
        redirect: '/loan/apply',
        meta: {
            label: '贷款管理',
            icon: 'DollarOutlined'
        },
        children: [
            {
                path: '/loan/apply',
                name: 'applyList',
                component: () => import('@/views/loan/applyList.vue'),
                meta: {
                    label: '贷款申请管理',
                    keepAlive: true
                }
            },
            {
                path: '/loan/applyList',
                name: 'applyListNew',
                component: () => import('@/views/loan/applyListNew.vue'),
                meta: {
                    label: '新贷款申请管理',
                    keepAlive: true
                }
            },
            {
                path: '/loan/in',
                name: 'inList',
                component: () => import('@/views/loan/inList.vue'),
                meta: {
                    label: '贷中管理',
                    keepAlive: true
                }
            },
            {
                path: '/loan/drawList',
                name: 'drawList',
                component: () => import('@/views/loan/drawList.vue'),
                meta: {
                    label: '支用管理',
                    keepAlive: true
                }
            }
        ]
    },
    {
        path: '/customer',
        name: 'customer',
        redirect: '/customer/list',
        meta: {
            label: '客户管理',
            icon: 'TeamOutlined'
        },
        children: [
            {
                path: '/customer/list',
                name: 'customerList',
                component: () => import('@/views/customer/list.vue'),
                meta: {
                    label: '客户列表',
                    keepAlive: true
                }
            },
            {
                path: '/customer/bank',
                name: 'bankList',
                component: () => import('@/views/customer/bankList.vue'),
                meta: {
                    label: '银行卡管理',
                    keepAlive: true
                }
            },
            {
                path: '/customer/upper',
                name: 'upList',
                component: () => import('@/views/customer/upList.vue'),
                meta: {
                    label: '3PL管理',
                    keepAlive: true
                }
            }
        ]
    },
    {
        path: '/shop',
        name: 'shop',
        meta: {
            label: '店铺管理',
            icon: 'ShopOutlined'
        },
        children: [
            {
                path: '/shop/list',
                name: 'shopList',
                component: () => import('@/views/shop/list.vue'),
                meta: {
                    label: '店铺列表',
                    keepAlive: true
                }
            },
            {
                path: '/shop/offer',
                name: 'offerList',
                component: () => import('@/views/shop/offerList.vue'),
                meta: {
                    label: 'offer发放表',
                    keepAlive: true
                }
            }
        ]
    },
    {
        path: '/report',
        name: 'report',
        meta: {
            label: '报告管理',
            icon: 'FileTextOutlined'
        },
        children: [
            {
                path: '/report/workList',
                name: 'reportWorkList',
                component: () => import('@/views/report/workList.vue'),
                meta: {
                    label: '审批工作台',
                    keepAlive: true
                }
            },
            {
                path: '/report/list',
                name: 'reportList',
                component: () => import('@/views/report/list.vue'),
                meta: {
                    label: '尽调报告',
                    keepAlive: true
                }
            }
        ]
    },
    {
        path: '/channel',
        name: 'channel',
        meta: {
            label: '渠道管理',
            icon: 'ApartmentOutlined'
        },
        children: [
            {
                path: '/channel/list',
                name: 'channelList',
                component: () => import('@/views/channel/channelList.vue'),
                meta: {
                    label: '渠道管理',
                    keepAlive: true
                }
            },
            {
                path: '/channel/link',
                name: 'linkList',
                component: () => import('@/views/channel/linkList.vue'),
                meta: {
                    label: '链接管理',
                    keepAlive: true
                }
            }
        ]
    },
    {
        path: '/product',
        name: 'product',
        component: () => import('@/views/product/list.vue'),
        meta: {
            label: '产品管理',
            icon: 'AppstoreOutlined',
            keepAlive: true
        }
    },
    {
        path: '/risk',
        name: 'risk',
        redirect: '/risk/list',
        meta: {
            label: '风险管理',
            icon: 'WarningOutlined'
        },
        children: [
            {
                path: '/risk/list',
                name: 'riskList',
                component: () => import('@/views/risk/list.vue'),
                meta: {
                    label: '风险项列表',
                    keepAlive: true
                }
            }
        ]
    },
    {
        path: '/system',
        name: 'system',
        redirect: '/system/dict',
        meta: {
            label: '系统管理',
            icon: 'SettingOutlined'
        },
        children: [
            {
                path: '/system/user',
                name: 'user',
                component: () => import('@/views/system/user.vue'),
                meta: {
                    label: '用户管理',
                    keepAlive: true
                }
            },
            {
                path: '/system/role',
                name: 'role',
                component: () => import('@/views/system/role.vue'),
                meta: {
                    label: '角色管理',
                    keepAlive: true
                }
            },
            {
                path: '/system/depart',
                name: 'depart',
                component: () => import('@/views/system/depart.vue'),
                meta: {
                    label: '部门管理',
                    keepAlive: true
                }
            },
            {
                path: '/system/permission',
                name: 'permission',
                component: () => import('@/views/system/permission.vue'),
                meta: {
                    label: '权限管理',
                    keepAlive: true
                }
            },
            {
                path: '/system/dict',
                name: 'dict',
                component: () => import('@/views/system/dict.vue'),
                meta: {
                    label: '数据字典',
                    keepAlive: true
                }
            }
        ]
    }
]

export default menuList
